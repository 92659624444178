<template>
    <v-container class="mypage" :style="!isMobile ? 'width: 100%' : ''">
        <v-row fill-height>
            <v-col cols="12" class="mypage_tit">
                <v-row align="center" justify="center">
                    <v-col>
                        <v-img
                            lazy-src="https://picsum.photos/id/11/10/6"
                            :contain="true"
                            max-height="150"
                            max-width="150"
                            :src="brandLogo"
                        ></v-img>
                    </v-col>
                    <v-col class="">
                        <v-row class="text-center ma-0" no-gutters>
                            <v-col cols="12" class="text-center pa-0">
                                <v-card flat class="" tile>
                                    {{ shopName }}
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card flat class="" tile>
                                    {{ telephone }}
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="login_yn == true" cols="12" class="mt-3 mb-3" >
                <v-row align="center" justify="center">
                    <v-card
                        class="text-center pl-3 pr-3 rounded-pill"
                        outlined
                        color="primary"
                    >
                        <v-row>
                            <v-card-title class="text-body-1 white--text">보유금액</v-card-title>
                            <v-card-title class="text-h6 yellow--text text--lighten-2 ">{{ formatPrice(cash) }}</v-card-title>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>

            <v-col cols="12">
                <ProductCard
                    class="ml-1 mr-1 mb-2"
                    @childs-event="goProductDetail"
                    v-for="(row, i) in shopProducts"
                    :row="row"
                    :key="i"
                />
            </v-col>

            <!-- <v-col cols="12">
                <v-row v-if="login_yn == true">
                    <v-col> 보유 금액 : {{ formatPrice(cash) }} </v-col>
                </v-row>
            </v-col> -->

            <v-col cols="12">
                <!-- Iterate -->
                <!-- <v-row v-if="login_yn == true && hasProduct == true"> -->
                <v-row v-if="!test_mode == true">
                    <v-col
                        cols="12"
                        v-for="(product, i) in cartProducts"
                        :key="i"
                    >
                        <v-row
                            class="text-center text-subtitle-2"
                            justify="center"
                            align="center"
                        >
                            <v-col cols="5" class="">
                                {{ product.product_name }}
                            </v-col>
                            <v-col cols="1">1</v-col>
                            <v-col cols="3"
                                >{{ formatPrice(product.sale_price) }}
                            </v-col>
                            <v-col cols="2" class="pa-0 ma-0">
                                <v-btn @click="cancelProduct(i)" small>
                                    취소
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row class="text-center">
                            <v-col>결제금액</v-col>
                            <v-col>{{ formatPrice(total_amount) }}</v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" class="text-center justify-between mb-10">
                        <v-row>
                            <v-col>
                                <v-btn>취소</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn @click="payment" color="primary"
                                    >결제</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>







        <!-- ********************************************************************************** -->
        <!-- Dialogs                    ******************************************************* -->
        <!-- ********************************************************************************** -->
        <!-- Dialog : Cash Charge Start  ****************************************************** -->
        <v-dialog v-model="recharge_dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> 잔액이 부족합니다 </v-card-title>

                <v-card-text> 금액을 충전하시겠습니까? </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text  @click="recharge_dialog = false" >
                        아니오
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="recharge_dialog = false"
                    >
                        충전
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- ********************************************************************************** -->
        <!-- buy_dialog   ********************************************************************* -->
        <v-dialog v-model="buy_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    구매 하시겠습니까?
                </v-card-title>
                <v-card-text> 해당 상품을 구매하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click="buy_dialog = false">
                        취소
                    </v-btn>
                    <v-btn color="#5EB4F9" text @click="payment">
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="result_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    {{ result_data.title }}
                </v-card-title>
                <v-card-text> {{ result_data.content }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#5EB4F9" text @click="result_dialog = false">
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <v-dialog v-model="w_alert" max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    로그인 / 회원가입 
                </v-card-title>
                <v-card-text> 로그인이 필요합니다 </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="w_alert = false">
                        닫기
                    </v-btn>
                    <v-btn color="green darken-1" text @click="goLogIn">
                        로그인/회원가입
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirm_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5">
                    구매완료하였습니다.
                </v-card-title>
                <v-card-text> 시작화면으로 돌아갑니다.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="#5EB4F9" text @click="goBack"> 확인 </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- *************************************************************************** -->
        <!-- get_dialog **************************************************************** -->
        <v-dialog v-model="get_dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> 구매 </v-card-title>
                <v-card-text> 지갑에 등록했습니다 </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="get_dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- *************************************************************************** -->
        <!-- Dialog : Sold Out Start  ******************************************************* -->
        <v-dialog v-model="soldout_dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> 안내 </v-card-title>

                <v-card-text> 기프티콘 품절 </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="soldout_dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        

        <!-- *************************************************************************** -->
        <!-- Dialog : Get Server Start  ******************************************************* -->
        <v-dialog v-model="reuse_dialog" max-width="290">
            <v-card>
                <v-card-title class="text-h5"> 안내 </v-card-title>

                <v-card-text> {{ reuse_text }} </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="reuse_dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog : Get Server End  ***************************************************** -->
        <!-- ****************************************************************************** -->
        <v-overlay :value="loading_bar">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import axios from "../../../service/axios";

export default {
    name: "shop",
    components: {
        ProductCard: () => import("@/components/card/ProductCard"),
        //   OtcFooter: () => import("@/components/OtcFooter"),
    },

    props: ["data"],

    data() {
        return {
            test_mode: true,
            login_yn: false,
            brandLogo: null,
            shopName: null,
            telephone: null,
            shopProducts: null,
            cartProducts: [],
            total_amount: 0,
            w_alert: false,
            get_dialog: false,
            soldout_dialog: false,
            cash: 0,
            recharge_dialog: false,
            confirm_dialog: false,
            buy_dialog: false,
            reuse_dialog: false,
            hasProduct: false,
            loading_bar: false,
            reuse_text:'기프티콘이 지갑에 저장되었습니다',
        };
    }, // data

    computed: {
        // ...mapState('G_NAME', [])
    }, // computed

    created() {
        if (this.$cookies.get("token") != null) {
            this.login_yn = true;
            // const UserData = this.$store.getters.getUserData;
            this.cash = this.$store.getters.getBalance;
        }
        window.addEventListener("beforeunload", this.beforeWindowUnload);
    },

    beforeDestroy() {
        window.removeEventListener("beforeunload", this.beforeWindowUnload);
    },

    async mounted() {
        document.documentElement.scrollTop = 0;
        // const testApi = await this.axios.get('https://reqres.in/api/users?page=2')
        // console.log('Test Api : ', testApi.datax)

        // window.on('beforeunload', function() {
        //     document.documentElement.scrollTop(0);
        // });

        let shopData 
        let storedProduct = this.$store.getters.getShopDetail;
        console.log("stored data  : ", storedProduct); // shop data
        if(storedProduct) shopData = storedProduct
        else shopData = this.data
        console.log("shopData : ", shopData); // shop data

        const starbucks =
            "https://res.cloudinary.com/cycon/image/upload/cafe_logo/starbucks_logo_wgbyhs.png";
        const ediya =
            "https://res.cloudinary.com/cycon/image/upload/v1631538607/cafe_logo/ediya_logo_wi5ccn.jpg";
        const mega =
            "https://res.cloudinary.com/mothcar/image/upload/v1647413379/cafe_shop_logo/mega_shop_logo.png";
        const paik =
            "https://res.cloudinary.com/dw4yrr8eq/image/upload/v1651468441/paikslogo_vkzvxg.jpg";
        // Paul change logic list == 0
        // if (shopData.brand == "스타벅스") {
        //     this.brandLogo = starbucks;
        // } else if(shopData.brand == "이디야") {
        //     this.brandLogo = ediya;
        // } else if(shopData.brand == "빽다방") {
        //     this.brandLogo = paik;
        // } else {
        //     this.brandLogo = mega;
        // }

        switch(shopData.brand) {
            case "스타벅스":
                this.brandLogo = starbucks;
                break;
            case "이디야":
                this.brandLogo = ediya;
                break;
            case "메가커피":
                this.brandLogo = mega;
                break;
            case "빽다방":
                this.brandLogo = paik;
                break;
        }

        this.shopName = shopData.shop_name;
        this.telephone = shopData.tel;

        const articles = await this.axios.get("/article/getArticleByBrand", {params:{options: shopData.brand}});
        console.log("get Brand Article : ", articles.data.data.item);
        const items = articles.data.data.item;
        // let items = raw.filter(
        //     (v, i, a) =>
        //         a.findIndex((t) => t.product_name === v.product_name) === i
        // );
        // console.log("Unique item : ", items);
        
        if (items.length > 0) {
            this.hasProduct = true;
        } else {
            this.soldout_dialog = true;
            return;
        }
        this.shopProducts = items;

        let storeProducts = this.$store.getters.getCart;
        this.settle(storeProducts);
    }, // mounted

    methods: {
        beforeWindowUnload() {
            document.documentElement.scrollTop(0);
        },

        goBack() {
            this.$router.go(-1);
        },

        goLogIn() {
            this.$store.commit({ type: "setSubPage", sub_page: "로그인" });
            this.$router.push("/sign/login");
            this.$store.commit({
                type: "setCurrentRoute",
                route: "login",
            });
            console.log("Go.. Log in....");
        },

        goProductDetail(item) {
            // console.log("Click item ...........: ", item);
            this.$store.commit({ type: "setSubPage", sub_page: "상품안내" });
            this.$store.commit({ type: "setProduct", product: item });
            this.$router.push({
                name: "product_detail",
                params: { data: item },
            });
        },

        async payment(item) {
            this.loading_bar = true;
            console.log('Item : ', item)

            const token = this.$cookies.get("token");
            const params = {
                accessKey: token,
                buyItem: item, // product detail
            };
            console.log("Payment params .... ", params);
            const raw = await axios.post("/payment/buyGifticon", params);
            if (raw) this.loading_bar = false;

            // console.log("from buyGifticon-Server : ", raw.data.data.item);
            
            const userBalance = raw.data.data.item;
            console.log("from buyGifticon-Server : ", userBalance);
            this.$store.commit({ type: "setBalance", balance: userBalance });
            this.cash = userBalance;
            // delete cart
            this.cartProducts = [];
            this.total_amount = 0;
            this.$store.commit({ type: "setCart", product: null });
            this.reuse_text = '기프티콘이 지갑에 저장되었습니다'
            this.reuse_dialog = true;

            // this.$router.go(-1);
        },

        addProduct(item) {
            if (this.login_yn == true) {
                // console.log('Pass............ : ', item)
                // this.$store.commit({ type: "setProduct", product: item });
                // let storeProducts = this.$store.getters.getCart;
                // let amount = 0;
                // for (let i = 0; i < storeProducts.length; i++) {
                //     amount += storeProducts[i].sale_price;
                // }
                let amount = item.sale_price;
                const myDeposit = this.$store.getters.getBalance;

                if (myDeposit >= amount) {
                    // this.$store.commit({
                    //     type: "setCart",
                    //     product: item,
                    // });
                    this.get_dialog = true;
                } else {
                    this.recharge_dialog = true;
                }
            } else {
                this.w_alert = true;
            }

            // let storeProducts = this.$store.getters.getCart;
            // // console.log('store Products....', storeProducts)
            // this.settle(storeProducts);
        },

        cancelProduct(i) {
            this.$store.commit({
                type: "cancelProduct",
                index: i,
            });
            let storeProducts = this.$store.getters.getCart;
            this.settle(storeProducts);
        },

        settle(storeProducts) {
            this.cartProducts = storeProducts;
            let amount = 0;
            for (let i = 0; i < storeProducts.length; i++) {
                amount += storeProducts[i].sale_price;
            }
            this.total_amount = amount;
        },

        formatPrice(value) {
            try {
                return (
                    value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "원"
                );
            } catch {
                return value + "원";
            }
        },
    }, // methods
};
</script>
